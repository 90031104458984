<template>
  <div
    class="grid grid-cols-4 gap-x-10 items-center w-full whitespace-nowrap border-b py-4 px-5 text-gray-600 bg-white z-10"
    :class="{
      'cursor-pointer': expandable,
      'cursor-default': !expandable
    }"
    @click="expandable && (expanded = !expanded)"
  >
    <div class="flex space-x-2 whitespace-pre-wrap">
      <div v-if="tooltip" v-tooltip="tooltip">
        <span
          class="whitespace-normal"
          :class="{
            'font-semibold': expandable || titleBold
          }"
        >
          {{ camelCaseToWords(title) }}
        </span>
      </div>
      <span
        v-else
        :data-cy="title"
        class="whitespace-normal"
        :class="{
          'font-semibold': expandable || titleBold
        }"
      >
        {{ camelCaseToWords(title) }}
      </span>

      <counter-pill
        v-if="expandable && counter"
        data-cy="counter-pill"
        :count="dataCount"
        :color="COLORS[9]"
      />
    </div>
    <div class="flex items-center space-x-2">
      <span>{{ result }}</span>
      <update-service-record
        v-if="!expandable && getUpdateRecord(title)"
        :service-name="t('DEALS.LEXIS_NEXIS.SERVICE_NAME')"
        :model="getUpdateRecord(title) as ServiceUpdateRecord"
        @record:update="handleUpdateApplicationRecord"
      />
    </div>

    <div v-if="showPercentage" class="flex items-center justify-end">
      <strong>{{ $t("DEALS.TOTAL_SCORE") }}</strong>
      <LfPill
        class="max-w-max h-8 uppercase ml-4"
        :color-index="getColorIndex(indicator)"
      >
        {{ indicator || 0 }} %
      </LfPill>
    </div>

    <SuccessIndicator v-else-if="showSuccessIndicator" :value="!!indicator" />

    <span v-else>
      {{ indicator }}
    </span>

    <dropdown-chevron
      v-if="expandable"
      class="justify-self-end"
      :is-open="expanded"
    />
  </div>
  <div
    v-if="expandable && expanded"
    class="w-full bg-disabled shadow-inner p-4"
  >
    <slot name="expansion" />
  </div>
</template>

<script setup lang="ts">
import { ref, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { COLORS } from "@/helpers/constants";
import { camelCaseToWords } from "@/helpers/formatting";
import { useUpdateDealDataFromService } from "@/hooks/deals";

import SuccessIndicator from "@/components/ui/SuccessIndicator.vue";
import LfPill from "@/components/ui/text/LfPill.vue";
import CounterPill from "@/components/CounterPill.vue";
import UpdateServiceRecord from "@/components/deals/dataServices/UpdateServiceRecord.vue";
import type { ServiceUpdateRecord } from "@/models/common";

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  titleBold: {
    type: Boolean,
    default: false
  },
  result: {
    type: [String, Boolean],
    default: ""
  },
  indicator: {
    type: [String, Number, Boolean],
    default: ""
  },
  expandable: {
    type: Boolean,
    default: false
  },
  showPercentage: {
    type: Boolean,
    default: false
  },
  showSuccessIndicator: {
    type: Boolean,
    default: false
  },
  dataCount: {
    type: Number,
    default: 0
  },
  counter: {
    type: Boolean,
    default: true
  },
  tooltip: {
    type: String,
    default: ""
  },
  update: {
    type: Object as PropType<ServiceUpdateRecord[]>,
    required: false
  }
});

const { t } = useI18n();
const { handleUpdateApplicationRecord } = useUpdateDealDataFromService();

const expanded = ref(true);

const getColorIndex = (result: string | number | boolean) => {
  const resToNumber = Number(result);

  if (resToNumber >= 95) {
    return 0;
  }
  if (resToNumber >= 85) {
    return 5;
  }
  return 2;
};

const getUpdateRecord = (title: string) =>
  props.update?.find((update) => update.field === camelCaseToWords(title));
</script>
